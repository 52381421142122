header,
.logo,
.primary-menu,
.secondary-menu,
.language-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
}

header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 61px;
    background-color: var(--HEADER-BG);
    color: #ccc;
    padding: 0 23px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.logo {
    padding-right: 19px;
    margin-right: 19px;
    border-right: 1px solid white;

}

.logo img {
    height: 27px;
}

.logo img:last-child {
    display: none;
}

.primary-menu{
    gap: 19px;
}

.secondary-menu {
    margin-left: auto;
    gap: 15px;
}

.language-picker img {
    height: 22px;
}

.signup-button {
    background-color: #7399BD;
    padding: 4.5px 14.5px;
    border-radius: 10px;
    color: var(--SECONDARY-TEXT);
}

.primary-menu-dropdown,
.primary-menu-dropdown--checked {
    position: relative;
    width: 24px;
    height: 24px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    margin-left: 15px;
}

.hamburger-icon::after,
.hamburger-icon::before {
    content: "";
}


.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
    position: absolute;
    display: block;
    width: 24px;
    height: 2px;
    background-color: white;
    transition: all ease-in-out 0.3s;
}

.hamburger-icon::before {
    margin-top: -8px;
}

.hamburger-icon::after {
    margin-top: 8px;
}

.primary-menu-dropdown--checked .hamburger-icon {
    background-color: rgba(255, 255, 255, 0);
}

.primary-menu-dropdown--checked .hamburger-icon::after {
    margin-top: 0;
    transform: rotate(45deg)
}

.primary-menu-dropdown--checked .hamburger-icon::before {
    margin-top: 0;
    transform: rotate(-45deg);
}

.primary-menu .secondary-menu{
    display: none;
}


@media (max-width: 650px) {
    .logo img:first-child {
        display: none;
    }

    .logo img:last-child {
        display: inline-block;
    }
}

@media (max-width: 520px) {
    .primary-menu-dropdown, .primary-menu-dropdown--checked{
        display: flex;
    }

    .primary-menu{
        flex-direction: column;
        gap: 0;
        position: absolute;
        width: 100%;
        left: 0;
        align-items: start;
        top: 61px;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.3s ease-out;
    }

    .primary-menu p, .primary-menu .secondary-menu{
        background-color: rgba(28, 71, 127, 0.8);
        padding: 10px 19px;
        border-bottom: 1px solid white;
        width: 100%;
    }

    .primary-menu .secondary-menu{
        padding: 15px 19px;
        justify-content: end;
    }

    .primary-menu--dropped{
        transform: scaleY(1);
    }
}

@media (max-width: 360px) {
    .primary-menu-dropdown, .primary-menu-dropdown--checked{
        margin-left: auto;
    }
    header > .secondary-menu{
       display: none 
    }

    .primary-menu .secondary-menu{
        display: flex;
    }

    .logo {
        border-right: none;
    }
}
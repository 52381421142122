.list{
    list-style-type: none;
}

.list-item{
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #c9c9c9;
    color: #434343;
}

.tag{
    background-color: rgb(0, 36, 24);
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 5px;
    margin-right: 13px;
    color: white;
}

.numbering{
    width: 20px;
    height: 20px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #434343;
    border-radius: 99px;
    margin-right: 13px;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root{
  --PRIMARY-TEXT: #000;
  --SECONDARY-TEXT: #FFF;
  --PRIMARY-BG: #E9E2CE;
  --SECONDARY-BG: #FFF;
  --HEADER-BG: #1C477F;
}

:root {
  font-family: "Poppins", "Verdana", sans-serif;
}

.section-title{
  font-size: 30px;
  margin-bottom: 20px;
}

.padding-container{
  padding: 31px calc((100% - 930px) / 2);
  padding-bottom: 50px;
}

@media (max-width: 980px){
  .padding-container{
      padding: 31px 40px;
      padding-bottom: 50px;
  }
}

@media (max-width: 700px){
  .padding-container{
      padding: 31px 30px;
      padding-bottom: 50px;
  }
}

@media (max-width: 470px){
  .padding-container{
      padding: 31px 19px;
      padding-bottom: 50px;
  }
}
.banner{
    background-color: var(--PRIMARY-BG);
    padding-top: 80px;
}

.banner .welcoming-sent{
    text-transform: uppercase;
    font-size: 60px;
    line-height: 100px;
    font-weight: bold;
    margin-bottom: 30px;
}

.banner .welcoming-sent img{
    width: 69px;
    margin-right: 4px;
}

.banner .roadmaps p:first-child{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
}

.banner .roadmaps .card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;
}

.banner .roadmaps .card-container p{
    display: none;
    text-align: left !important;
}

.card-container > *{
    width: 211px;
}

.banner .roadmaps p:last-child{
    align-items: center;
    font-weight: bold;
    margin-top: 25px;
    text-align: center;
}

.banner .roadmaps p:last-child img{
    vertical-align: middle;
    transform: scale(0.8);
}

@media (max-width: 980px){
    .banner .roadmaps .card-container p{
        display: block;
    }

    .banner .roadmaps > p:last-child{
        display: none
    }

    .card-container > *{
        width: calc(33% - 18px);
    }
}

@media (max-width: 700px){

    .banner .welcoming-sent{
        font-size: 45px;
        line-height: 85px;
    }

    .banner .welcoming-sent img{
        width: 54px;
    }

    .banner .roadmaps p:first-child{
        text-align: center;
    }

    .banner .roadmaps .card-container p{
        display: none;
    }

    .banner .roadmaps > p:last-child{
        display: block
    }

    .card-container > *{
        width: calc(50% - 12.5px)
    }
}

@media (max-width: 470px){
    .banner {
        padding-top: 31px;
        padding-bottom: 31px;
    }

    .banner .welcoming-sent{
        font-size: 40px;
        line-height: 80px;
    }

    .banner .welcoming-sent img{
        width: 49px;
    }
}

@media (max-width: 425px){

    .banner .welcoming-sent{
        font-size: 9vw;
        line-height: 15vw;
    }

    .banner .welcoming-sent img{
        width: 10vw;
    }

    .card-container > *{
        width: 100%;
    }
}


.modules > p{
    margin-top: 10px;
    color: #3f3f3f;
}

.modules p img{
    vertical-align: middle;
    transform: scale(0.8);
}

.signup{
    background-color: var(--PRIMARY-BG);
    display: flex;
    justify-content: space-between;
}

.signup .input-group{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.signup .input-group label{
    margin-bottom: 5px;
}

.signup .input-group input{
    width: 378px;
    height: 48px;
    padding: 0 10px;
    border-radius: 5px;
    font-size: 1rem;
    border: 1px solid black;
}

.signup button{
    background-color: #7399BD;
    width: 378px;
    height: 48px;
    padding: 0 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    margin-top: 10px;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.4);
}

.signup .alternative p{
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: bold;
}

.signup .alternative .options div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 81px;
    height: 48px;
    background-color: var(--SECONDARY-BG);
    border: 1px solid black;
    border-radius: 5px;
}

.signup .alternative .options div img{
    width: 24px;
}

.signup > img{
    width: 415px;
    margin-top: 30px;
}

@media (max-width: 920px){
    .signup > img{
        width: 350px;
        height: auto;
    }
}

@media (max-width: 860px){
    .signup > img{
        display: none
    }
}

@media (max-width: 430px){
    .signup .input-group input{
        width: 100%;
    }

    .signup button{
        width: 100%;
    }
}
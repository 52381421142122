.rect-card{
    height: 71px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to top, var(--HEADER-BG) 0%, var(--HEADER-BG) 50%, var(--SECONDARY-BG) 50%, var(--SECONDARY-BG) 100%);
    border-radius: 7px;
    background-size: 100% 142px;
    background-position: 100% 10%;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.4);
    transition: background-position 0.3s ease-out;
}

.rect-card:hover{
    background-position: 100% 100%;
}

.rect-card img{
    width: 99px;
    height: 46px;
    transition: filter 0.3s ease-out;
}

.rect-card:hover img{
    filter: invert(1)
}